export const SETTINGS_ROUTING = {
  Branding: 'branding',
  Versions: 'versions',
  Support: 'support',
  PlatformAccess: 'platform-access',
  Nps: 'nps',
  Trackers: 'trackers',
  SignInMethods: 'sign-in-methods',
  Widget: 'widget',
  Menu: 'Menu',
} as const;
