import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Trackers } from '@models/partner/trackers';
import { TrackersWebService } from '@webservices/trackers/themes.webservice';
import { Observable } from 'rxjs';

export function trackersResolver(route: ActivatedRouteSnapshot): Observable<Trackers> {
  const trackersWebservice = inject(TrackersWebService);
  const partnerId = route.paramMap.get('partnerId');

  if (partnerId === null) {
    throw new Error('partnerId is required');
  }

  return trackersWebservice.get(partnerId);
}
